<template>
    <div>
        <div class="ui mobile reversed stackable grid" v-if="timestamped">
            <div class="column" style="flex: 1; min-width: 0;" v-if="customer">
                <router-link :to="{
                    name: 'Customer',
                    params: {
                        id: customer.id
                    }
                }">
                    <img :src="photo" class="ui avatar image"/>
                    <span class="ui small link header">
                        {{customer.name}}
                    </span>
                </router-link>
            </div>
            <div class="column" style="width: auto;">
                <div class="field-label">
                    <strong>{{prescription.prescription_datetime|formatdate("D MMM YYYY [at] h:mmA")}}</strong>
                    <template v-if="prescription.created !== prescription.prescription_datetime">
                        &#160;
                        <span :data-tooltip="`Created on ${$moment(prescription.created).format('D MMM YYYY hh:mmA')}`" data-position="top center" data-variation="small">
                            <i class="info circle yellow icon"></i>
                        </span>
                    </template>
                    <div v-if="created_by">
                        by <strong>{{created_by|displayname}}</strong>
                    </div>
                    <div class="ui hidden fitted divider"></div>
                    <div class="ui light text" style="text-transform: none; letter-spacing: normal;">
                        <em>Last updated on {{prescription.updated|formatdate("D MMM YYYY [at] h:mmA")}}</em>
                        <a href="javascript:void(0);" style="margin-left: 0.5em;" v-if="isAdmin" @click="showPrescriptionHistory"><i class="teal history icon"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui stackable grid" :class="relaxed">
            <div class="column" style="width: 38.2%;">
                <div v-if="!timestamped">
                    <div class="ui sub light text header">Prescription Date / Time</div>
                    {{prescription.prescription_datetime|formatdate("D MMM YYYY [at] h:mmA")}}
                </div>
                <div class="ui very relaxed list">
                    <div class="item">
                        <div class="ui sub light text header">Main Treatment</div>
                        <div class="ui hidden fitted divider"></div>
                        <div><label class="ui purple small code label">{{prescription.main_treatment_code}}</label> <strong class="ui purple text">{{prescription.main_treatment_description}}</strong></div>
                    </div>
                    <div class="item" v-if="prescription.addon_treatments && prescription.addon_treatments.length">
                        <div class="ui sub light text header">Additional Treatment</div>
                        <div class="ui hidden fitted divider"></div>
                        <template v-for="(addon_treatment, addon_treatment_index) in prescription.addon_treatments">
                            <div :key="`addon_${addon_treatment.code}`">
                                <label class="ui purple small code label">{{addon_treatment.code}}</label> <strong class="ui purple text">{{addon_treatment.description}}</strong>
                            </div>
                            <div class="ui hidden fitted divider" v-if="addon_treatment_index < prescription.addon_treatments.length" :key="`addon_divider_${addon_treatment.code}`"></div>
                        </template>
                    </div>
                    <div class="item" v-if="prescription.machine_treatments && prescription.machine_treatments.length">
                        <div class="ui sub light text header">Machine Treatment</div>
                        <div class="ui hidden fitted divider"></div>
                        <template v-for="(machine_treatment, machine_treatment_index) in prescription.machine_treatments">
                            <div :key="`machine_${machine_treatment.code}`">
                                <label class="ui purple small code label">{{machine_treatment.code}}</label> <strong class="ui purple text">{{machine_treatment.description}}</strong>
                            </div>
                            <div class="ui hidden fitted divider" v-if="machine_treatment_index < prescription.machine_treatments.length" :key="`machine_divider_${machine_treatment.code}`"></div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="column" style="flex: 1; min-width: 0;">
                <div>
                    <div class="ui sub light text header">Consultation Notes</div>
                    <div v-html="prescription.note"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "prescription-detail",
    props: {
        prescription: {
            type: Object,
            required: true
        },
        customer: {
            type: Object
        },
        relaxed: {
            type: String,
            default: ""
        },
        readonly: {
            type: Boolean,
            default: false
        },
        timestamped: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            created_by: null,
            photo: require("@/assets/images/photo.jpg")
        };
    },
    methods: {
        showPrescriptionHistory() {
            if(this.isAdmin) {
                this.$emit("show:history");
            }
        }
    },
    watch: {
        prescription: {
            deep: true,
            immediate: true,
            handler(prescription, _prescription) {
                if((prescription && !_prescription) || (prescription.created_by !== _prescription.created_by)) {
                    this.$http.get(`users/${this.prescription.created_by}`).then((response) => {
                        this.created_by = response.data;
                    }).catch((error) => {
                        console.error(error);
                    });
                }
            }
        },
        customer: {
            deep: true,
            immediate: true,
            handler() {
                if(this.customer && this.customer.photo_ext) {
                    let photo = new URL(`${process.env.VUE_APP_MEDIA_BASE_URL}/customer/photo/${this.customer.id}.min${this.customer.photo_ext}`);
                    photo.searchParams.set("timestamp", (new Date()).getTime());

                    const image = new Image();
                    image.onload = () => {
                        this.photo = image.src;
                    };

                    image.onerror = () => {
                        if(!image.hasError) {
                            photo = new URL(`${process.env.VUE_APP_MEDIA_BASE_URL}/customer/photo/${this.customer.id}${this.customer.photo_ext}`);
                            photo.searchParams.set("timestamp", (new Date()).getTime());

                            image.hasError = true;
                            image.src = photo.toString();
                        } else {
                            image.src = this.photo;
                        }
                    };
                    image.src = photo.toString();
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.avatar.image {
    font-size: 1.15em;
}

.ui.code.label {
    font-family: monospace;
}
</style>