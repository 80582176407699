<template>
    <div>
        <div class="comment">
            <h4><div class="ui purple sub header">Comment</div></h4>
            <div class="ui comments">
                <div class="comment">
                    <div class="content">
                        <span class="author">{{created_by|displayname}}</span>
                        <div class="metadata">
                            <span class="date">{{aftersales.aftersales_datetime|formatdate("D MMM YYYY [at] h:mmA")}}</span>
                        </div>
                        <div class="text">
                            {{aftersales.comment}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui hidden section divider"></div>

        <div class="collection">
            <h4><div class="ui purple sub header">Collection Detail</div></h4>
            <div class="ui relaxed list">
                <div class="item">
                    <div class="ui sub light text header">Sales Total</div>
                    <div><h5 class="ui header">RM {{aftersales.sales_total|tocurrency}}</h5></div>
                </div>
                <div class="item">
                    <div class="ui sub light text header">Deposit</div>
                    <div><h5 class="ui header">RM {{(aftersales.deposit? aftersales.deposit : 0)|tocurrency}}</h5></div>
                </div>
                <div class="item" v-if="aftersales.deposit && outstanding">
                    <div class="ui sub light text header">Outstanding</div>
                    <div><h5 class="ui red label">RM {{outstanding|tocurrency}}</h5></div>
                </div>
                <div class="item">
                    <div class="ui sub light text header">Date of Collection</div>
                    <div>
                        <h5 class="ui header">
                            <template v-if="aftersales.collection_date">
                                {{aftersales.collection_date|formatdate("D MMM YYYY")}}
                            </template>
                            <template v-else>N/A</template>
                        </h5>
                    </div>
                </div>
                <div class="item">
                    <div class="ui sub light text header">Mode of Collection</div>
                    <div><h5 class="ui header">{{payment_mode}}</h5></div>
                </div>
                <div class="item">
                    <div class="ui sub light text header">Instalment</div>
                    <div><h5 class="ui header">{{aftersales.instalment? "Yes" : "No"}}</h5></div>
                </div>
            </div>

            <template v-if="(!customer || !customer.contact_no || !customer.email) && timestamped">
                <div class="ui hidden divider"></div>

                <div class="ui light text">
                    <small>
                        <em>Last updated on {{aftersales.updated|formatdate("D MMM YYYY [at] h:mmA")}}</em>
                        <a href="javascript:void(0);" style="margin-left: 0.5em;" v-if="isAdmin" @click="showAfterSalesHistory"><i class="teal history icon"></i></a>
                    </small>
                </div>
            </template>
        </div>

        <template v-if="customer && customer.contact_no && customer.email">
            <div class="ui hidden section divider"></div>
            <div>
                <h4><div class="ui purple sub header">Contact Info</div></h4>
                <div class="ui relaxed list">
                    <div class="item" v-if="customer.contact_no">
                        <a class="content" :href="`tel:${customer.contact_no}`">
                            <i class="ui phone darkteal icon text"></i> {{customer.contact_no}}
                        </a>
                    </div>
                    <div class="item" v-if="customer.email">
                        <a :href="`mailto:${customer.email}`">
                            <i class="ui envelope darkteal icon text"></i> {{customer.email}}
                        </a>
                    </div>
                </div>
            </div>

            <template v-if="timestamped">
                <div class="ui hidden divider"></div>

                <div class="ui light text">
                    <small>
                        <em>Last updated on {{aftersales.updated|formatdate("D MMM YYYY [at] h:mmA")}}</em>
                        <a href="javascript:void(0);" style="margin-left: 0.5em;" v-if="isAdmin" @click="showAfterSalesHistory"><i class="teal history icon"></i></a>
                    </small>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import PaymentMode from "@/assets/constants/paymentMode";

export default {
    name: "after-sales",
    props: {
        aftersales: {
            type: Object,
            required: true
        },
        customer: {
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        timestamped: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            created_by: null
        };
    },
    created() {
        this.$http.get(`users/${this.aftersales.created_by}`).then((response) => {
            this.created_by = response.data;
        }).catch((error) => {
            console.error(error);
        });
    },
    computed: {
        payment_mode() {
            let payment_mode = "N/A";
            if(this.aftersales.payment_mode) {
                if(PaymentMode[this.aftersales.payment_mode]) {
                    payment_mode = PaymentMode[this.aftersales.payment_mode].toDescription();
                } else {
                    payment_mode = this.aftersales.payment_mode;
                }
            }
            return payment_mode;
        },
        outstanding() {
            return this.aftersales.sales_total - this.aftersales.deposit;
        }
    },
    methods: {
        showAfterSalesHistory() {
            if(this.isAdmin) {
                this.$emit("show:history");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.collection.grid {
    > .row {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

h4 + .ui.list {
    margin-top: 1.5em;
}
</style>