<template>
    <div class="ui history container">
        <div class="ui stackable grid mobile reversed segment" :class="{sliding}">
            <transition name="history-detail" @before-enter="sliding = true;" @after-enter="sliding = false;">
                <div class="eleven wide column" v-show="!is_sm || history">
                    <template v-if="history">
                        <template v-if="is_sm">
                            <h3>
                                <a href="javascript:void(0);" @click="hideHistory"><i class="fontello-right"></i></a>
                            </h3>
                            <div class="ui hidden medium divider"></div>
                        </template>
                        <div>
                            <h3 class="ui header">
                                {{title}}
                                <div class="ui fitted hidden divider"></div>
                                <div class="sub header">
                                    {{history.updated|formatdate("D MMM YYYY h:mmA")}}
                                    <i class="icon" style="margin: 0;">&bull;</i>
                                    <template v-if="history.action === 'INSERT'">
                                        Created
                                    </template>
                                    <template v-else-if="history.action === 'UPDATE'">
                                        Updated
                                    </template>
                                    <template v-else-if="history.action === 'DELETE'">
                                        Deleted
                                    </template>
                                    <template v-else>
                                        Unknown action
                                    </template>
                                    by
                                    <strong>
                                        <template v-if="history.updated_by === _user.id">You</template>
                                        <template v-else>
                                            {{history.updatedby_firstname}} {{history.updatedby_lastname}}
                                        </template>
                                    </strong>
                                </div>
                            </h3>
                        </div>
                        <div class="ui divider"></div>
                        <div class="ui hidden divider"></div>
                        <div>
                            <slot name="detail" :history="history"/>
                        </div>
                        <template v-if="histories.length > 1">
                            <div class="ui hidden divider"></div>
                            <div class="ui center aligned pagination-arrows text">
                                <div class="ui buttons">
                                    <button class="ui black icon button" type="button" title="Older Revision" @click="viewHistory(history_index + 1, $event)" :disabled="history_index === (histories.length - 1)"><i class="arrow left icon"></i></button>
                                    <button class="ui black icon button" type="button" title="More Recent Revision" @click="viewHistory(history_index - 1, $event)" :disabled="history_index === 0"><i class="arrow right icon"></i></button>
                                </div>
                            </div>
                        </template>

                        <div class="ui inverted dimmer" :class="{active: viewing}">
                            <div class="ui loader"></div>
                        </div>
                    </template>
                </div>
            </transition>
            <transition name="history-log">
                <div class="five wide history-log column" v-show="!is_sm || !history">
                    <div class="inner-container">
                        <h3 class="ui header">History Log</h3>
                        <div class="ui hidden divider"></div>
                        <div class="content">
                            <div class="ui very relaxed history list">
                                <a class="ui link item" href="javascript:void(0);" v-for="(hist, index) in histories" :key="`history_${index}`" :class="{selected: hist.selected}" @click="viewHistory(index)">
                                    <div class="content">
                                        <div class="ui two column unstackable middle aligned grid">
                                            <div class="column" style="flex: 1; min-width: 0;">
                                                <div class="ui black text">
                                                    <template v-if="hist.action === 'INSERT'">
                                                        Created
                                                    </template>
                                                    <template v-else-if="hist.action === 'UPDATE'">
                                                        Updated
                                                    </template>
                                                    <template v-else-if="hist.action === 'DELETE'">
                                                        Deleted
                                                    </template>
                                                    <template v-else>
                                                        Unknown action
                                                    </template>
                                                    by
                                                    <strong>
                                                        <template v-if="hist.updated_by === _user.id">You</template>
                                                        <template v-else>
                                                            {{hist.updatedby_firstname}} {{hist.updatedby_lastname}}
                                                        </template>
                                                    </strong>
                                                </div>
                                                <div class="ui fitted hidden divider"></div>
                                                <div class="ui light text">{{hist.updated|formatdate("D MMM YYYY [at] h:mmA")}}</div>
                                            </div>
                                            <div class="column" style="width: auto;">
                                                <a href="javascript:void(0);">
                                                    <template v-if="is_sm">
                                                        <i class="angle right icon"></i>
                                                    </template>
                                                    <template v-else>
                                                        View
                                                    </template>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a v-if="page" href="javascript:void(0);" @click="list(page)">
                                    Load older records
                                </a>
                            </div>
                        </div>
                        <div class="ui inverted dimmer" :class="{active: loading}">
                            <div class="ui loader"></div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "history",
    props: {
        title: {
            type: String,
            default: "Revision"
        },
        url: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            histories: [],
            history_index: null,
            history: null,
            page: 0,
            loading: true,
            sliding: false
        };
    },
    mounted() {
        this.list(1);
    },
    methods: {
        list(page) {
            this.page = page;
            this.loading = true;

            this.$http.get(this.url, {
                params: {
                    page: this.page,
                    size: 10,
                    sort: "updated",
                    order: "DESC"
                }
            }).then((response) => {
                if(page === 1) {
                    this.histories = response.data || [];
                } else {
                    this.histories.push(...(response.data || []));
                }

                const total_count = response.headers["x-total-count"]? +response.headers["x-total-count"] : this.histories.length;
                if(total_count > this.histories.length) {
                    this.page++;
                } else {
                    this.page = 0;
                }

                if(!this.is_sm && !this.history && this.histories.length) {
                    this.viewHistory(0);
                }
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        },
        viewHistory(history_index, event) {
            this.viewing = true;
            this.histories.forEach((_history) => {
                this.$set(_history, "selected", false);
            });

            const history = this.histories[history_index];
            history.selected = true;



            setTimeout(() => {
                this.history = history;
                this.history_index = history_index;
                this.viewing = false;

                this.$forceUpdate();

                if(event) {
                    let $button = $(event.target).closest("button");
                    $button.blur();

                    if(this.page && history_index === (this.histories.length - 2)) {
                        this.list(this.page);
                    }
                }
            }, 100);
        },
        hideHistory() {
            this.histories.forEach((_history) => {
                this.$set(_history, "selected", false);
            });
            this.history = null;
            this.history_index = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.history.container {
    color: rgba(0,0,0,.87);

    .ui.contact.list {
        > .item {
            .content {
                > label {
                    display: block;
                    margin-bottom: 0.5em;
                }
            }
        }
    }

    > .ui.segment {
        min-height: calc(100vh - 150px);
        border-radius: 10px;

        .ui.dimmer {
            border-radius: 10px 0 0 10px;
        }

        @media only screen and (min-width: 768px) {
            border-radius: 20px;

            .ui.dimmer {
                border-radius: 20px;
            }

            .history-log {
                border-radius: inherit;
                background: #f5f6fa;

                .inner-container {
                    position: sticky;
                    top: 0;
                }

                .ui.list {
                    .item {
                        &:not(:last-child) {
                            padding-bottom: 1rem;
                        }

                        &:not(:first-child) {
                            padding-top: 1rem;
                        }

                        .ui.grid {
                            border-radius: 10px;
                        }

                        &:hover {
                            .ui.grid {
                                background: rgba(0,0,0,0.03);
                            }
                        }

                        &.selected {
                            .ui.grid {
                                background: rgba(0,0,0,0.03);
                            }
                        }
                    }
                }
            }

            &.ui.grid {
                > .column {
                    padding: 2em;
                }
            }
        }
    }

    .pagination-arrows {
        position: sticky;
        bottom: 1em;
        z-index: 1;
    }

    @media only screen and (max-width: 767px) {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
        max-width: none !important;

        .ui.segment.ui.grid {
            justify-content: flex-end;

            &.sliding {
                overflow: hidden;
            }

            > .column {
                padding-left: 1em !important;
                padding-right: 1em !important;
            }

            > .history-log {
                flex: 1;
            }
        }

        .history-log-leave-active,
        .history-detail-enter-active {
            transition: all 0.5s;
        }

        .history-log-leave-active {
            position: absolute;
        }

        .history-log-leave {
            opacity: 1;
        }

        .history-log-leave-to {
            opacity: 0;
        }

        .history-detail-enter-active {
            position: relative;
            z-index: 9;
            background: #fff;
            border-radius: inherit;
        }

        .history-detail-enter {
            transform: translate(100%, 0);
        }

        .history-detail-enter-to {
            transform: none;
        }

        .history-detail-leave-active,
        .history-log-enter-active {
            transition: all 0.5s;
        }

        .history-detail-leave {
            opacity: 1;
            transform: none;
        }

        .history-detail-leave-to {
            opacity: 0;
            transform: translate(100%, 0);
        }

        .history-detail-leave-active {
            position: absolute;
            background: #fff;
        }

        .history-log-enter {
            opacity: 0;
        }

        .history-log-enter-to {
            opacity: 1;
        }
    }
}
</style>