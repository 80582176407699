import { render, staticRenderFns } from "./AfterSales.vue?vue&type=template&id=405d33dc&scoped=true&"
import script from "./AfterSales.vue?vue&type=script&lang=js&"
export * from "./AfterSales.vue?vue&type=script&lang=js&"
import style0 from "./AfterSales.vue?vue&type=style&index=0&id=405d33dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405d33dc",
  null
  
)

export default component.exports