<template>
    <div>
        <div v-if="consultation">
            <button class="ui circular icon alt teal right floated small edit button" type="button" @click="editConsultation" v-if="!readonly"><i class="pencil icon"></i></button>
            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">About Visit</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">How did you find out about us?</div>
                            <div class="relaxed list">
                                <div class="item" v-if="consultation.advertisement">
                                    <strong>Advertisement:</strong> {{consultation.advertisement}}
                                </div>
                                <div class="item" v-if="consultation.insert_pamphlet">
                                    <strong>Insert / Pamphlet:</strong> {{consultation.insert_pamphlet}}
                                </div>
                                <div class="item" v-if="consultation.promotional_offer">
                                    <strong>Promotional Offer:</strong> {{consultation.promotional_offer}}
                                </div>
                                <div class="item" v-if="consultation.family_friend">
                                    <strong>Family / Friend:</strong> {{consultation.family_friend}}
                                </div>
                                <div class="item" v-if="consultation.other_findout">
                                    <strong>Other:</strong> <em>{{consultation.other_findout}}</em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">How often do you take a facial treatment?</div>
                            <div class="description"><strong>{{$te(`facial_treatment_frequency.${consultation.facial_treatment_frequency}`)? $t(`facial_treatment_frequency.${consultation.facial_treatment_frequency}`) : consultation.facial_treatment_frequency}}</strong></div>
                        </div>
                    </div>

                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">What brand of skin care products are you currently using?</div>
                            <div class="relaxed list">
                                <div class="item" v-if="consultation.cleanser_brand">
                                    <strong>Cleanser:</strong> {{consultation.cleanser_brand}}
                                </div>
                                <div class="item" v-if="consultation.toner_brand">
                                    <strong>Toner:</strong> {{consultation.toner_brand}}
                                </div>
                                <div class="item" v-if="consultation.moisturizer_brand">
                                    <strong>Moisturizer:</strong> {{consultation.moisturizer_brand}}
                                </div>
                                <div class="item" v-if="consultation.suncream_brand">
                                    <strong>Sun Cream:</strong> {{consultation.suncream_brand}}
                                </div>
                                <div class="item" v-if="consultation.serum_brand">
                                    <strong>Serum:</strong> {{consultation.serum_brand}}
                                </div>
                                <div class="item" v-if="consultation.mask_care_brand">
                                    <strong>Mask Care:</strong> {{consultation.mask_care_brand}}
                                </div>
                                <div class="item" v-if="consultation.other_skincare_brand">
                                    <strong>Other:</strong> <em>{{consultation.other_skincare_brand}}</em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">What are the concerns you have for your <strong><em>skin</em></strong>?</div>
                            <div class="ui list">
                                <div class="item" v-for="skin_concern in consultation.skin_concerns" :key="`skin_concern_${skin_concern}`">
                                    <strong>{{$t(`skin_concern["${skin_concern}"]`)}}</strong>
                                </div>
                                <div class="item" v-if="consultation.other_skin_concern">
                                    <strong>Other:</strong> <em>{{consultation.other_skin_concern}}</em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">What are the concerns you have for your <strong><em>eyes</em></strong>?</div>
                            <div class="ui list">
                                <div class="item" v-for="eye_concern in consultation.eye_concerns" :key="`eye_concern_${eye_concern}`">
                                    <strong>{{$t(`eye_concern["${eye_concern}"]`)}}</strong>
                                </div>
                                <div class="item" v-if="consultation.other_eye_concern">
                                    <strong>Other:</strong> <em>{{consultation.other_eye_concern}}</em>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Allergic reaction to any product or skincare treatment?</div>
                            <div class="description">
                                <strong>
                                    <span class="ui red text" v-if="consultation.allergy">{{consultation.allergy}}</span>
                                    <template v-else>None</template>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">Medical History</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Have you had any surgery / cosmetic surgery?</div>
                            <div class="description">
                                <strong>{{consultation.surgery? consultation.surgery : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Have you been to any Dermatologist?</div>
                            <div class="description">
                                <strong>{{consultation.visited_dermatology? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Are you on any controlled medication now?</div>
                            <div class="description">
                                <strong>{{consultation.controlled_medication? consultation.controlled_medication : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Have you been on acne medication?</div>
                            <div class="ui list" v-if="consultation.acne_medications.length">
                                <div class="item" v-for="acne_medication in consultation.acne_medications" :key="`acne_medication_${acne_medication}`">
                                    <strong>{{$t(`acne_medication["${acne_medication}"]`)}}</strong>
                                </div>
                            </div>
                            <div class="description" v-else><strong>No</strong></div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Do you have any health conditions?</div>
                            <div class="ui list" v-if="consultation.health_conditions.length || consultation.other_health_condition">
                                <div class="item" v-for="health_condition in consultation.health_conditions" :key="`health_condition_${health_condition}`">
                                    <strong>{{$t(`health_condition["${health_condition}"]`)}}</strong>
                                </div>
                                <div class="item" v-if="consultation.other_health_condition">
                                    <strong>Other:</strong> <em>{{consultation.other_health_condition}}</em>
                                </div>
                            </div>
                            <div class="description" v-else><strong>No</strong></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">Lifestyle</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Do you exercise regularly?</div>
                            <div class="description">
                                <strong>{{consultation.exercise_regularly? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Does your diet contain a lot of carbohydrate?</div>
                            <div class="description">
                                <strong>{{consultation.high_carbohydrate_diet? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">How many hours do you sleep every night?</div>
                            <div class="description">
                                <strong>
                                    <em v-if="consultation.sleep_hours === ''">
                                        N/A
                                    </em>
                                    <template v-else>
                                        <em>{{consultation.sleep_hours}}</em> hours
                                    </template>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Does your face get sun-burned easily?</div>
                            <div class="description">
                                <strong>{{consultation.sunburn_prone? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Have you done any laser / peeling?</div>
                            <div class="description">
                                <strong>{{consultation.laser_peeling? consultation.laser_peeling : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">Hydration</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">How much plain water do you consume daily?</div>
                            <div class="description">
                                <strong>
                                    <em v-if="consultation.daily_water_consumption === ''">
                                        N/A
                                    </em>
                                    <template v-else>
                                        <em>{{consultation.daily_water_consumption}}</em> liters
                                    </template>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Do you consume alcoholic beverages frequently?</div>
                            <div class="description">
                                <strong>{{consultation.frequent_alcoholic? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Does your face show obvious dryness/tightness?</div>
                            <div class="description">
                                <strong>{{consultation.obvious_dryness_tightness? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Do you take alcohol/coffee/tea/carbonated drinks?</div>
                            <div class="description">
                                <strong>{{consultation.alcohol_coffee_tea_carbonated? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">Sebum</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Does your face feel oily during the day?</div>
                            <div class="description">
                                <strong>{{consultation.daytime_oily_face? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Do you experience skin break-outs?</div>
                            <div class="description">
                                <strong>{{consultation.skin_breakout? "Yes" : "No"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ui hidden divider"></div>

            <div class="ui vertical basic segment">
                <label class="ui top left attached purple label">Treatment Preference</label>
                <div class="ui very relaxed consultation list">
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Preferred massage technique?</div>
                            <div class="description">
                                <strong>{{$t(`preferred_massage_technique["${consultation.preferred_massage_technique}"]`)}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <i class="square full purple icon"></i>
                        <div class="content">
                            <div class="ui light text">Preferred extraction technique?</div>
                            <div class="description">
                                <strong>{{$t(`preferred_extraction_technique["${consultation.preferred_extraction_technique}"]`)}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="customer.gender === $gender.FEMALE">
                <div class="ui hidden divider"></div>

                <div class="ui vertical basic segment">
                    <label class="ui top left attached purple label">For Female Clients Only</label>
                    <div class="ui very relaxed consultation list">
                        <div class="item">
                            <i class="square full purple icon"></i>
                            <div class="content">
                                <div class="ui light text">Are you pregnant or trying to conceive?</div>
                                <div class="description">
                                    <strong>{{consultation.pregnant_conceive? "Yes" : "No"}}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="ui right aligned light text" v-if="timestamped">
                <small>
                    <em>Last updated on {{consultation.updated|formatdate("D MMM YYYY [at] h:mmA")}}</em>
                    <a href="javascript:void(0);" style="margin-left: 0.5em;" v-if="isAdmin" @click="showConsultationHistory"><i class="teal history icon"></i></a>
                </small>
            </div>
        </div>
        <div v-else>
            No consultation found for this customer. Click <a href="javascript:void(0);" @click="editConsultation">here</a> to create one.
        </div>

        <div ref="consultationmodal" class="ui hidden modal">
            <div class="content">
                <div :key="`consultationform_${consultationform_key}`">
                    <keep-alive>
                        <component ref="consultationform" :id="`consultationform${_uid}`" :is="consultationform" :formdata="consultationformdata" @submit="nextConsultation" @prev="prevConsultation"/>
                    </keep-alive>
                </div>
            </div>
            <div class="actions">
                <button type="button" class="ui alt teal left floated button" v-if="consultationform === 'medical-info-form'" @click="prevConsultation">Back</button>
                <button type="submit" :form="`consultationform${_uid}`" class="ui submit teal button">{{(consultationform === "medical-info-form")? "Update" : "Next"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import clonedeep from "lodash.clonedeep";
import merge from "lodash.merge";

import medicalinfoMessage from "@/i18n/consultation/medicalinfo";
import todayvisitMessage from "@/i18n/consultation/todayvisit";

import TodayVisitForm from "@/views/consultation/TodayVisitForm";
import MedicalInfoForm from "@/views/consultation/MedicalInfoForm";

export default {
    name: "consultation-detail",
    i18n: {
        sharedMessages: merge({}, medicalinfoMessage, todayvisitMessage)
    },
    components: {
        TodayVisitForm,
        MedicalInfoForm
    },
    props: {
        consultation: {
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        timestamped: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            consultationform_key: (new Date()).getTime(),
            consultationformdata: {},
            consultationform: "today-visit-form",
            consultationforms: [
                "today-visit-form",
                "medical-info-form"
            ],
            opened_modals: [],
            history_popped: false
        };
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.consultationmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "consultationmodal_opened");
                this.opened_modals.push("consultationmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "consultationmodal";
                });
            },
            onHidden: () => {
                this.consultationform_key = (new Date()).getTime();
                this.consultationform = this.consultationforms[0];
                this.consultationformdata = clonedeep(this.consultation);
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    computed: {
        customer() {
            return this.consultation?.profile || {};
        }
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        editConsultation() {
            $(this.$refs.consultationmodal).modal("show");
        },
        nextConsultation(formdata) {
            Object.assign(this.consultationformdata, formdata);

            let form_index = this.consultationforms.indexOf(this.consultationform);
            if(form_index > -1) {
                if(form_index < this.consultationforms.length - 1) {
                    this.$refs.consultationform.submitting = false;
                    this.consultationform = this.consultationforms[form_index + 1];
                } else if(form_index === this.consultationforms.length - 1) {
                    let formdata = clonedeep(this.consultationformdata);
                    delete formdata.profile;

                    this.$http.put(`consultations/${this.consultationformdata.id}`, formdata).then((response) => {
                        this.$emit("update:consultation", response.data);
                        $(this.$refs.consultationmodal).modal("hide");

                        this.$toasted.success("Consultation updated successfully.", {
                            duration: 3000
                        });
                    }).catch((error) => {
                        console.error(error);

                        this.$toasted.error("Unable to update consultation detail.", {
                            duration: 3000
                        });
                    }).finally(() => {
                        this.$refs.consultationform.submitting = false;
                    });
                }
            }
        },
        prevConsultation() {
            let form_index = this.consultationforms.indexOf(this.consultationform);
            if(form_index) {
                this.consultationform = this.consultationforms[form_index - 1];
            }
        },
        showConsultationHistory() {
            if(this.isAdmin) {
                this.$emit("show:history");
            }
        }
    },
    watch: {
        consultation: {
            deep: true,
            immediate: true,
            handler() {
                if(this.consultation) {
                    this.consultationformdata = clonedeep(this.consultation);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.ui.consultation.list {
    .description {
        margin-top: 0.75em;
    }

    .relaxed.list {
        .item {
            padding: .25em 0;
        }
    }
}

.ui.edit.button {
    position: sticky;
    z-index: 1;
    top: calc(56px + 1em);
}
</style>