<template>
    <div class="personal-info">
        <div class="ui very relaxed list">
            <slot name="header" :customer="customer"/>
            <div class="item">
                <div class="content">
                    <label class="ui light text">Gender</label>
                    <div class="header">{{customer.gender|capitalize}}</div>
                </div>
            </div>
            <div class="item">
                <div class="content">
                    <label class="ui light text">NRIC / Passport</label>
                    <div class="header">{{customer.id_no}}</div>
                </div>
            </div>
            <div class="item">
                <div class="content">
                    <label class="ui light text">Date of Birth</label>
                    <div class="header">{{customer.dob|formatdate("D MMM YYYY")}}</div>
                </div>
            </div>
            <div class="item">
                <div class="content">
                    <label class="ui light text">Age</label>
                    <div class="header">
                        <template v-if="!isBlankObject(age)">
                            <template v-if="age.years">
                                {{$tc("common.n_year", age.years)}}
                            </template>
                            <template v-if="age.months">
                                &amp; {{$tc("common.n_month", age.month)}}
                            </template>
                            old
                        </template>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="content">
                    <label class="ui light text">Race</label>
                    <div class="header">{{$te(`race.${customer.race}`)? $t(`race.${customer.race}`) : customer.race}}</div>
                </div>
            </div>
            <div class="item">
                <div class="content">
                    <label class="ui light text">Occupation</label>
                    <span class="header" style="display: inline;">
                        {{$te(`occupation.${customer.occupation}`)? $t(`occupation.${customer.occupation}`) : customer.occupation}}
                    </span>
                    <span class="ui black text">({{customer.designation}})</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import merge from "lodash.merge";
import personalinfoMessage from "@/i18n/consultation/personalinfo";

export default {
    name: "personal-info",
    i18n: {
        sharedMessages: merge({}, personalinfoMessage)
    },
    props: {
        customer: {
            type: Object,
            required: true
        }
    },
    computed: {
        age() {
            let age = {};

            if(this.customer) {
                let _age = this.$moment.duration(this.$moment().diff(this.$moment(this.customer.dob)));
                age.months = _age.months();
                age.years = _age.years();
            }
            return age;
        }
    }
};
</script>

<style lang="scss" scoped>
.personal-info {
    .ui.list {
        > .item {
            .content {
                > label {
                    display: block;
                    margin-bottom: 0.5em;
                }
            }
        }
    }
}
</style>