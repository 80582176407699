<template>
    <div>
        <div>
            <div class="ui simple middle aligned grid">
                <div class="mobile only row">
                    <div class="column">
                        <router-link class="back link" :to="backRoute? backRoute : { name: 'Customers' }">
                            <i class="fontello-left"></i>
                        </router-link>
                    </div>
                </div>
                <div class="row">
                    <div class="sixteen wide mobile eight wide tablet eight wide computer column">
                        <h3 class="ui header">
                            Customer Detail
                        </h3>
                    </div>
                    <div class="computer only tablet only eight wide tablet eight wide computer right aligned column">
                        <router-link class="ui small alt button" :to="backRoute? backRoute : { name: 'Customers' }">
                            <i class="fontello-left"></i> Back
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="ui hidden divider"></div>
        <div class="ui stackable customer grid">
            <div class="six wide column">
                <div class="ui error icon mobile oustanding message" v-if="customer && outstanding_prescriptions.length">
                    <i class="close icon" @click="closeMessage"></i>
                    <i class="exclamation circle icon"></i>
                    <div class="content">
                        <div class="header">
                            Alert
                        </div>
                        {{customer.name}} has <strong>{{outstanding_prescriptions.length}}</strong> outstanding record{{(outstanding_prescriptions.length > 1)? "s" : ""}}
                    </div>
                </div>
                <transition name="vue-fade">
                    <div>
                        <div class="ui fluid card" v-if="customer">
                            <div class="center aligned content">
                                <pre></pre>
                                <div class="photo-wrapper">
                                    <label for="photo" class="ui circular photo image">
                                        <img :src="photo.src"/>
                                    </label>
                                    <file-upload
                                        accept="image/*"
                                        name="photo"
                                        class="ui icon circular basic teal small upload button"
                                        v-model="photo.files"
                                        @input-filter="inputFilter"
                                        @input-file="inputFile"
                                        ref="photouploader">
                                        <i class="camera icon"></i>
                                    </file-upload>
                                </div>
                                <h3 class="ui purple text header">{{customer.name}}</h3>
                            </div>
                            <div class="extra content">
                                <button type="button" class="ui circular icon alt teal right floated small edit button" @click="editCustomer" style="margin-top: 1em;"><i class="pencil icon"></i></button>
                                <div class="ui hidden medium divider"></div>
                                <personal-info :customer="customer"/>
                                <div class="ui hidden divider"></div>
                                <div class="ui light text">
                                    <small>
                                        <em>Last updated on {{customer.updated|formatdate("D MMM YYYY [at] h:mmA")}}</em>
                                        <a href="javascript:void(0);" style="margin-left: 0.5em;" v-if="isAdmin" @click="showCustomerHistory"><i class="teal history icon"></i></a>
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="ui fluid card" v-else>
                            <div class="content">
                                <div class="ui placeholder">
                                    <div class="image"></div>
                                    <div class="header">
                                        <div class="medium line"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="extra content">
                                <div class="ui placeholder">
                                    <div class="header">
                                        <div class="very short line"></div>
                                        <div class="medium line"></div>
                                    </div>
                                    <div class="paragraph">
                                        <div class="short line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="column">
                <div class="ui error icon desktop oustanding message" v-if="customer && outstanding_prescriptions.length">
                    <i class="close icon" @click="closeMessage"></i>
                    <i class="exclamation circle icon"></i>
                    <div class="content">
                        <div class="header">
                            Alert
                        </div>
                        {{customer.name}} has <strong>{{outstanding_prescriptions.length}}</strong> outstanding record{{(outstanding_prescriptions.length > 1)? "s" : ""}}
                    </div>
                </div>
                <transition name="vue-fade">
                    <div>
                        <div class="ui segment" v-if="customer">
                            <div class="ui top left attached purple label">Contact Info</div>
                            <div class="ui stackable contact grid">
                                <div class="one column row">
                                    <div class="column">
                                        <div class="ui list">
                                            <div class="item">
                                                <i class="ui home darkteal icon text"></i>
                                                <div class="content">
                                                    {{customer.address}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="two column row">
                                    <div class="column">
                                        <div class="ui list">
                                            <div class="item">
                                                <i class="ui envelope darkteal icon text"></i>
                                                <div class="content">
                                                    <a :href="`mailto:${customer.email}`">{{customer.email}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="ui list">
                                            <div class="item">
                                                <i class="ui phone darkteal icon text"></i>
                                                <div class="content">
                                                    <a :href="`tel:${customer.contact_no}`">{{customer.contact_no}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ui hidden fitted divider"></div>
                        </div>
                        <div class="ui segment" v-else>
                            <div class="ui placeholder">
                                <div class="header">
                                    <div class="very short line"></div>
                                    <div class="medium line"></div>
                                </div>
                                <div class="paragraph">
                                    <div class="short line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="ui hidden divider"></div>

                <transition name="vue-fade">
                    <div v-if="customer">
                        <div ref="tabbing" class="ui top attached tabular menu">
                            <a class="item" :class="{active: active_tab === 'consultation'}" data-tab="consultation"><span class="ui purple text">Consultation</span></a>
                            <a class="item" :class="{active: active_tab === 'prescriptions'}" data-tab="prescriptions"><span class="ui purple text">Treatment Prescriptions</span></a>
                        </div>
                        <div class="ui tab bottom attached segment" :class="{active: active_tab === 'consultation'}" data-tab="consultation">
                            <transition name="vue-fade">
                                <consultation :consultation="consultation" @update:consultation="consultationUpdated" @show:history="showConsultationHistory" timestamped/>
                            </transition>
                        </div>
                        <div class="ui tab bottom attached segment" :class="{active: active_tab === 'prescriptions', loading: loading_prescription}" data-tab="prescriptions">
                            <transition name="vue-slide-right-fade">
                                <div v-show="prescription">
                                    <div class="ui two column grid prescription-header">
                                        <div class="left aligned column">
                                            <button class="ui icon alt teal small button" type="button" title="Back to Treatment Prescription List" @click="closePrescription"><i class="fontello-left icon"></i> Back</button>
                                        </div>
                                        <div class="right aligned column">
                                            <button class="ui circular icon alt red small button" type="button" title="Delete Treatment Prescription" @click="deletePrescription(prescription)" v-if="isAdmin"><i class="trash alternate icon"></i> Delete</button>
                                            <button class="ui circular icon alt teal small button" type="button" title="Create After Sales Record" @click="editAfterSales(prescription)" v-if="prescription && !prescription.aftersales">
                                                <i class="icons">
                                                    <i class="file alternate outline icon"></i>
                                                    <i class="corner add icon"></i>
                                                </i>
                                            </button>
                                            <button class="ui circular icon alt teal small button" type="button" title="Edit Treatment Prescription" @click="editPrescription(prescription)"><i class="pencil icon"></i></button>
                                        </div>
                                    </div>
                                    <div class="ui hidden medium divider"></div>
                                    <prescription :prescription="prescription" @show:history="showPrescriptionHistory" relaxed="relaxed" timestamped v-if="prescription"/>
                                    <template v-if="prescription && prescription.aftersales && prescription.aftersales.created">
                                        <div class="ui section divider"></div>
                                        <div style="position: relative;">
                                            <div style="position: absolute; top: -0.75em; right: 0;">
                                                <button class="ui circular icon alt red small button" type="button" title="Delete After Sales Record" @click="deleteAfterSales(prescription)" v-if="isAdmin"><i class="trash alternate icon"></i> Delete</button>
                                                <button class="ui circular icon alt teal small button" type="button" title="Edit After Sales Record" @click="editAfterSales(prescription)"><i class="pencil icon"></i></button>
                                            </div>
                                            <after-sales id="aftersales-wrapper" :aftersales="prescription.aftersales" @show:history="showAfterSalesHistory" timestamped/>
                                        </div>
                                    </template>
                                    <template v-if="prescriptions.length > 1">
                                        <div class="ui hidden divider"></div>
                                        <div class="ui center aligned text prescription-footer">
                                            <div class="ui buttons">
                                                <button class="ui black icon button" type="button" title="Previous Treatment Prescription" @click="viewPrescription(prescription_index + 1, $event)" :disabled="prescription_index === (prescriptions.length - 1)"><i class="arrow left icon"></i></button>
                                                <button class="ui black icon button" type="button" title="Next Treatment Prescription" @click="viewPrescription(prescription_index - 1, $event)" :disabled="prescription_index === 0"><i class="arrow right icon"></i></button>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </transition>

                            <div v-show="!prescription">
                                <div class="ui right aligned text">
                                    <button class="ui black small icon mobile new-prescription button" type="button" @click="editPrescription()">Create New</button>
                                </div>
                                <div>
                                    <table class="ui very basic unstackable responsive compact prescriptions table">
                                        <thead>
                                            <tr>
                                                <th>Date / Time</th>
                                                <th>Treatment</th>
                                                <th class="right aligned"><button class="ui black small icon button" type="button" @click="editPrescription()">Create New</button></th>
                                            </tr>
                                        </thead>
                                        <template v-if="prescriptions.length">
                                            <transition-group tag="tbody" name="vue-server-side-paginated-list" mode="out-in">
                                                <tr class="top aligned" v-for="(_prescription, _prescription_index) in prescriptions" :key="`prescription_tr_${_prescription.id}`">
                                                    <td data-title="Date / Time">
                                                        {{_prescription.prescription_datetime|formatdate("DD MMM YYYY")}}
                                                        <div class="ui hidden fitted divider"></div>
                                                        <span class="ui light text">{{_prescription.prescription_datetime|formatdate("hh:mmA")}}</span>
                                                        <template v-if="_prescription.created !== _prescription.prescription_datetime">
                                                            &#160;
                                                            <span :data-tooltip="`Created on ${$moment(_prescription.created).format('D MMM YYYY hh:mmA')}`" data-position="right center" data-variation="small">
                                                                <i class="small info circle yellow icon"></i>
                                                            </span>
                                                        </template>
                                                    </td>
                                                    <td data-title="Treatment">
                                                        <div>
                                                            <strong class="ui purple text">{{_prescription.main_treatment_code}} {{_prescription.main_treatment_description}}</strong>
                                                            <label class="ui mini circular red outstanding label" :data-tooltip="_prescription.outstanding_amount? `Outstanding RM ${_prescription.outstanding_amount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : false" v-if="_prescription.has_outstanding">!</label>
                                                        </div>

                                                        <template v-if="(_prescription.addon_treatments && _prescription.addon_treatments.length) || (_prescription.machine_treatments && _prescription.machine_treatments.length)">
                                                            <div class="ui hidden fitted divider"></div><div class="ui hidden fitted divider"></div>
                                                            <table class="additional-treatments-table">
                                                                <tbody>
                                                                    <tr v-if="_prescription.addon_treatments && _prescription.addon_treatments.length">
                                                                        <td><label class="ui small purple label">Additional</label></td>
                                                                        <td>
                                                                            <div class="ui list">
                                                                                <div class="item" v-for="addon_treatment in _prescription.addon_treatments" :key="`addon_${addon_treatment.code}`">
                                                                                    <span class="ui grey text"><strong>{{addon_treatment.code}}</strong> {{addon_treatment.description}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-if="(_prescription.addon_treatments && _prescription.addon_treatments.length) && (_prescription.machine_treatments && _prescription.machine_treatments.length)">
                                                                        <td colspan="2"><div class="ui hidden fitted divider"></div></td>
                                                                    </tr>
                                                                    <tr v-if="_prescription.machine_treatments && _prescription.machine_treatments.length">
                                                                        <td><label class="ui small purple label">Machine</label></td>
                                                                        <td>
                                                                            <div class="ui list">
                                                                                <div class="item" v-for="machine_treatment in _prescription.machine_treatments" :key="`machine_${machine_treatment.code}`">
                                                                                    <span class="ui grey text"><strong>{{machine_treatment.code}}</strong> {{machine_treatment.description}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </template>
                                                        <div class="ui hidden medium divider"></div>
                                                        <div style="position: relative;">
                                                            <a href="javascript:void(0);" class="note-preview" data-position="top left"><i class="sticky note icon"></i> Preview Note</a>
                                                            <div class="ui flowing fluid popup">
                                                                <div class="content" v-html="clipNote(_prescription.note)"></div>
                                                                <div class="ui hidden medium divider"></div>
                                                                <div class="ui right aligned text">
                                                                    <a href="javascript:void(0);" @click="viewPrescription(_prescription_index)" title="View Treatment Prescription">Detail <i class="right angle icon"></i></a>
                                                                </div>
                                                            </div>
                                                            <span style="margin: 0 1.5em; opacity: 0.5;"></span>
                                                            <a href="javascript:void(0);" @click="viewPrescription(_prescription_index)"><i class="folder open icon"></i> Detail</a>
                                                        </div>
                                                    </td>
                                                    <td class="right aligned">
                                                        <div class="ui text compact action menu">
                                                            <div class="ui right dropdown fitted item">
                                                                <i class="ellipsis vertical teal icon"></i>
                                                                <div class="menu">
                                                                    <a class="item" href="javascript:void(0);" @click="editPrescription(_prescription)">Edit Treatment Prescription</a>
                                                                    <a class="item" href="javascript:void(0);" @click="editAfterSales(_prescription)">{{_prescription.aftersales? "Edit" : "Create"}} After Sales Record</a>
                                                                    <template v-if="isAdmin">
                                                                        <a class="item" href="javascript:void(0);" @click="deletePrescription(_prescription)"><span class="ui red text">Delete Treatment Prescription</span></a>
                                                                        <a class="item" href="javascript:void(0);" @click="deleteAfterSales(_prescription)" v-if="_prescription.aftersales"><span class="ui red text">Delete After Sales</span></a>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </transition-group>
                                        </template>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="3">No treatment prescription found.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui segment" v-else>
                        <div class="ui placeholder">
                            <div class="paragraph">
                                <div class="short line"></div>
                                <div class="medium line"></div>
                                <div class="very short line"></div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>

        <div ref="photocroppermodal" class="ui small hidden modal">
            <div class="content">
                <div class="avatar-edit-image" v-if="photo.files.length">
                    <img ref="imageeditor" :src="photo.files[0].url"/>
                </div>
            </div>
            <div class="actions">
                <button type="button" class="ui submit teal button" @click="savePhoto">Crop &amp; Save</button>
                <button type="button" class="ui alt red cancel button" @click="cancelUpload">Cancel</button>
            </div>
        </div>

        <div ref="customermodal" class="ui hidden modal">
            <div class="content">
                <personal-info-form ref="customerform" :id="`personalinfoform${_uid}`" :formdata="customerformdata" @submit="updateCustomer"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`personalinfoform${_uid}`" class="ui submit teal button">Update</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <div ref="prescriptionmodal" class="ui hidden prescription modal">
            <div class="content">
                <prescription-form ref="prescriptionform" :id="`prescriptionform${_uid}`" :prescription="prescriptionformdata" @change:saving="prescriptionSavingListener" @save:prescription="prescriptionSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`prescriptionform${_uid}`" class="ui submit teal button" :class="{loading: saving_prescription}" :disabled="saving_prescription">{{prescriptionformdata.id? "Update" : "Save"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <div ref="aftersalesmodal" class="ui hidden aftersales modal">
            <div class="content">
                <h3 class="ui header">{{aftersalesformdata.created? "Update After Sales Record" : "Create After Sales Record"}}</h3>
                <div class="ui hidden divider"></div>
                <after-sales-form :id="`aftersalesmodalform${_uid}`" :formdata="aftersalesformdata" @change:saving="afterSalesSavingListener" @save:aftersales="afterSalesSaved"/>
            </div>
            <div class="actions">
                <button type="submit" :form="`aftersalesmodalform${_uid}`" class="ui submit teal button" :class="{loading: saving_aftersales}" :disabled="saving_aftersales">{{aftersalesformdata.created? "Update" : "Submit"}}</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>

        <template v-if="isAdmin">
            <div ref="customerhistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`customerhistory_${customerhistory_key}`" :url="`customers/${id}/history`" title="Customer Revision">
                        <template #detail="{ history }">
                            <div class="ui two column stackable grid">
                                <div class="column">
                                    <personal-info :customer="history">
                                        <template #header="{ customer }">
                                            <div class="item">
                                                <div class="content">
                                                    <label class="ui light text">Name</label>
                                                    <div class="header">{{customer.name}}</div>
                                                </div>
                                            </div>
                                        </template>
                                    </personal-info>
                                </div>
                                <div class="column">
                                    <div class="ui very relaxed contact list">
                                        <div class="item">
                                            <div class="content">
                                                <label class="ui light text">Address</label>
                                                <div class="header">{{history.address}}</div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="content">
                                                <label class="ui light text">Email</label>
                                                <div class="header"><a :href="`mailto:${history.email}`">{{history.email}}</a></div>
                                            </div>
                                        </div>
                                        <div class="item">
                                            <div class="content">
                                                <label class="ui light text">Mobile No.</label>
                                                <div class="header"><a :href="`tel:${history.contact_no}`">{{history.contact_no}}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </history>
                </div>
            </div>

            <div ref="consultationhistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`consultationhistory_${consultationhistory_key}`" :url="`consultations/${consultation.id}/history`" title="Consultation Revision" v-if="consultation">
                        <template #detail="{ history }">
                            <consultation :consultation="history" readonly/>
                        </template>
                    </history>
                </div>
            </div>

            <div ref="prescriptionhistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`prescriptionhistory_${prescriptionhistory_key}`" :url="`prescriptions/${prescription.id}/history`" title="Treatment Prescription Revision" v-if="prescription">
                        <template #detail="{ history }">
                            <prescription :prescription="history" relaxed="relaxed" readonly/>
                        </template>
                    </history>
                </div>
            </div>

            <div ref="aftersaleshistorymodal" class="ui hidden large basic modal">
                <div class="content">
                    <history :key="`aftersaleshistory_${aftersaleshistory_key}`" :url="`aftersales/${prescription.aftersales.id}/history`" title="After Sales Revision" v-if="prescription && prescription.aftersales">
                        <template #detail="{ history }">
                            <after-sales :aftersales="history" readonly/>
                        </template>
                    </history>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import clip from "text-clipper";

import clonedeep from "lodash.clonedeep";

import History from "@/components/History";

import Consultation from "@/views/consultation/Consultation";
import PersonalInfo from "@/views/consultation/PersonalInfo";
import PersonalInfoForm from "@/views/consultation/PersonalInfoForm";
import PrescriptionForm from "@/views/consultation/PrescriptionForm";
import Prescription from "@/views/consultation/Prescription";
import AfterSales from "@/views/consultation/AfterSales";
import AfterSalesForm from "@/views/consultation/AfterSalesForm";
import FileUpload from "vue-upload-component";

export default {
    name: "customer",
    components: {
        History,
        Consultation,
        PersonalInfo,
        PersonalInfoForm,
        PrescriptionForm,
        Prescription,
        AfterSales,
        AfterSalesForm,
        FileUpload
    },
    data() {
        const id = this.$route.params.id;
        let active_tab = window.location.hash || "consultation";
        active_tab = active_tab.replace("#", "");

        return {
            id,
            customer: null,
            customerformdata: {},
            consultation: null,
            prescriptions: [],
            prescription: null,
            prescription_index: null,
            prescriptionformdata: {},
            aftersalesformdata: {},
            photo_placeholder: require("@/assets/images/photo.jpg"),
            photo: {
                src: require("@/assets/images/photo.jpg"),
                files: [],
                edit: false,
                cropper: false
            },
            active_tab,
            loading_prescription: false,
            saving_prescription: false,
            saving_aftersales: false,
            outstanding_prescriptions: [],
            customerhistory_key: (new Date()).getTime(),
            consultationhistory_key: (new Date()).getTime(),
            prescriptionhistory_key: (new Date()).getTime(),
            aftersaleshistory_key: (new Date()).getTime(),
            opened_modals: [],
            history_popped: false
        };
    },
    created() {
        const getCustomer = this.$http.get(`customers/${this.id}`).then((response) => {
            this.customer = response.data;
        }).catch((error) => {
            console.error(error);
            this.$toasted.error("Unable to load profile.", {
                duration: 3000
            });
        });

        const getConsultation = this.$http.get(`consultations/${this.id}`).then((response) => {
            this.consultation = response.data;
        }).catch((error) => {
            console.error(error);
            this.$toasted.error("Unable to load consultation detail.", {
                duration: 3000
            });
        });

        Promise.all([getCustomer, getConsultation]).then((_) => {
            this.$set(this.consultation, "profile", this.customer);

            if(this.consultation.allergy) {
                this.$toasted.error(`${this.customer.name} has allergy for ${this.consultation.allergy}.`, {
                    icon: (el) => {
                        el.className += " allergies icon";
                        return el;
                    },
                    action: {
                        text: "OK",
                        onClick: (e, toast) => {
                            toast.goAway(0);
                        }
                    }
                });
            }
        });

        this.listPrescriptions().then(() => {
            this.listOutstandingPrescriptions();
        });
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        $(this.$refs.photocroppermodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "photocroppermodal_opened");
                this.opened_modals.push("photocroppermodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "photocroppermodal";
                });
            }
        });

        $(this.$refs.customermodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "customermodal_opened");
                this.opened_modals.push("customermodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "customermodal";
                });
            }
        });

        $(this.$refs.prescriptionmodal).modal({
            closable: false,
            autofocus: false,
            onVisible: () => {
                history.pushState(null, "prescriptionmodal_opened");
                this.opened_modals.push("prescriptionmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "prescriptionmodal";
                });
            }
        });

        $(this.$refs.aftersalesmodal).modal({
            closable: false,
            onVisible: () => {
                history.pushState(null, "aftersalesmodal_opened");
                this.opened_modals.push("aftersalesmodal");
            },
            onHide: () => {
                //maybe use onHidden?
                if(!this.history_popped) {
                    history.back();
                }

                this.opened_modals = this.opened_modals.filter((modal) => {
                    return modal !== "aftersalesmodal";
                });
            }
        });

        if(this.isAdmin) {
            $(this.$refs.customerhistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "customerhistorymodal_opened");
                    this.opened_modals.push("customerhistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "customerhistorymodal";
                    });
                },
                onHidden: () => {
                    this.customerhistory_key = (new Date()).getTime();
                }
            });

            $(this.$refs.consultationhistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "consultationhistorymodal_opened");
                    this.opened_modals.push("consultationhistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "consultationhistorymodal";
                    });
                },
                onHidden: () => {
                    this.consultationhistory_key = (new Date()).getTime();
                }
            });

            $(this.$refs.prescriptionhistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "prescriptionhistorymodal_opened");
                    this.opened_modals.push("prescriptionhistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "prescriptionhistorymodal";
                    });
                },
                onHidden: () => {
                    this.prescriptionhistory_key = (new Date()).getTime();
                }
            });

            $(this.$refs.aftersaleshistorymodal).modal({
                centered: false,
                onVisible: () => {
                    history.pushState(null, "aftersaleshistorymodal_opened");
                    this.opened_modals.push("aftersaleshistorymodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "aftersaleshistorymodal";
                    });
                },
                onHidden: () => {
                    this.aftersaleshistory_key = (new Date()).getTime();
                }
            });
        }
    },
    updated() {
        this.$nextTick(() => {
            $(this.$el).find(".ui.action.menu:not(.disabled) .ui.dropdown").dropdown();
            $(this.$el).find(".note-preview").popup({
                inline: true,
                hoverable: true
            });

            $(this.$refs.tabbing).find(".item").tab({
                onVisible: (path) => {
                    // TODO: How to remember the tab; maybe use keep alive
                    // this.$router.push(`#${encodeURIComponent(path)}`);
                    // history.pushState(
                    //     {},
                    //     null,
                    //     `#${encodeURIComponent(path)}`
                    // );

                    this.active_tab = path;
                }
            });
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$toasted.clear();
        next();
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        listPrescriptions() {
            return this.$http.get("prescriptions", {
                params: {
                    filter: JSON.stringify({
                        customer_id: +this.id
                    }),
                    sort: "prescription_datetime",
                    order: "desc"
                }
            }).then((response) => {
                let prescriptions = response.data;

                this.flagOutstandingPrescriptions();

                this.$http.get(`customers/${this.id}/aftersales`).then((_response) => {
                    const aftersaless = _response.data;

                    aftersaless.forEach((aftersales) => {
                        const prescription = prescriptions.find((_prescription) => {
                            return _prescription.id === aftersales.id;
                        });

                        if(prescription) { //by right should always have
                            prescription.aftersales = aftersales;
                        }
                    });
                }).finally(() => {
                    this.prescriptions = prescriptions;
                });
            }).catch((error) => {
                console.error(error);
                this.$toasted.error("Unable to list prescriptions.", {
                    duration: 3000
                });
            });
        },
        listOutstandingPrescriptions() {
            this.$http.get(`customers/${this.id}/outstanding`).then((response) => {
                this.outstanding_prescriptions = response.data;

                this.flagOutstandingPrescriptions();
            }).catch((error) => {
                console.error(error);
            });
        },
        flagOutstandingPrescriptions() {
            if(this.prescriptions && this.outstanding_prescriptions) {
                this.prescriptions.forEach((prescription) => {
                    const outstanding_prescription = this.outstanding_prescriptions.find((outstanding_prescription) => {
                        return outstanding_prescription.id === prescription.id;
                    });

                    this.$set(prescription, "has_outstanding", !!outstanding_prescription);
                    this.$set(prescription, "outstanding_amount", outstanding_prescription? outstanding_prescription.aftersales.sales_total - outstanding_prescription.aftersales.deposit : null);
                });
            }
        },
        editCustomer() {
            this.customerformdata = clonedeep(this.customer);
            this.$nextTick(() => {
                $(this.$refs.customermodal).modal("show");
            });
        },
        updateCustomer(form) {
            this.$http.put(`customers/${this.customer.id}`, form.profile).then((response) => {
                Object.assign(this.customer, response.data);
                $(this.$refs.customermodal).modal("hide");

                // to refresh the history list
                this.customerhistory_key = (new Date()).getTime();

                this.$toasted.success("Customer detail updated successfully.", {
                    duration: 3000
                });
            }).catch((error) => {
                console.error(error);

                this.$toasted.error("Unable to update customer detail.", {
                    duration: 3000
                });
            }).finally(() => {
                this.$refs.customerform.submitting = false;
            });
        },
        consultationUpdated(consultation) {
            Object.assign(this.consultation, consultation);
        },
        cancelUpload() {
            this.$refs.photouploader.clear();
        },
        savePhoto() {
            this.photo.edit = false;

            let original_file = this.photo.files[0];
            let bin_string = atob(this.photo.cropper.getCroppedCanvas().toDataURL(original_file.type).split(",")[1]);
            let bin_array = new Uint8Array(bin_string.length);

            for(let i = 0; i < bin_string.length; i++) {
                bin_array[i] = bin_string.charCodeAt(i);
            }

            let file = new File([bin_array], original_file.name, {
                type: original_file.type
            });

            this.$refs.photouploader.update(original_file.id, {
                file,
                type: file.type,
                size: file.size,
                active: false //do not trigger upload, save manually
            });

            let formData = new FormData();
            formData.append("image", file);
            formData.append("id", this.id);

            this.$http.post("upload/customer/photo", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(() => {
                let reader = new FileReader;
                reader.onload = (e) => {
                    this.photo.src = e.target.result;
                };
                reader.readAsDataURL(file);

                this.$toasted.show(`${this.customer.name} photo updated.`, {
                    duration: 3000
                });
            }).catch(() => {
                this.$toasted.error(`Unable to update ${this.customer.name} photo.`, {
                    duration: 3000
                });
            });
        },
        inputFile(new_file, old_file, prevent) {
            if(new_file && !old_file) {
                this.$nextTick(() => {
                    this.photo.edit = true;
                });
            }

            if(!new_file && old_file) {
                this.photo.edit = false;
            }
        },
        inputFilter(new_file, old_file, prevent) {
            if(new_file && (!old_file || new_file.file !== old_file.file)) {
                new_file.url = "";

                let URL = window.URL || window.webkitURL;
                if(URL && URL.createObjectURL) {
                    new_file.url = URL.createObjectURL(new_file.file);
                }
            }
        },
        clipNote(note) {
            return clip(note, 150, {
                html: true,
                maxLines: 5
            });
        },
        closeMessage(event) {
            $(event.target).closest(".message").transition({
                animation: "fade",
                onComplete() {
                    $(this).remove();
                }
            });
        },
        closePrescription() {
            this.prescription = null;
        },
        viewPrescription(prescription_index, event) {
            const prescription = this.prescriptions[prescription_index];
            if(prescription) {
                this.loading_prescription = true;
                this.prescription = prescription;

                setTimeout(() => {
                    this.prescription_index = prescription_index;
                    this.loading_prescription = false;
                }, 100); // set 100ms delay for loading effect
            }

            if(event) {
                let $button = $(event.target).closest("button");
                $button.blur();
            }
        },
        editPrescription(prescription) {
            this.prescriptionformdata = prescription? clonedeep(prescription) : {
                customer_id: this.customer.id
            };

            $(this.$refs.prescriptionmodal).modal("show");
        },
        prescriptionSavingListener(saving) {
            this.saving_prescription = saving;
        },
        prescriptionSaved(prescription) {
            if(this.prescription) {
                if(this.prescription.aftersales) {
                    prescription.aftersales = this.prescription.aftersales;
                }
                this.prescription = prescription;
            }

            this.listPrescriptions();
            $(this.$refs.prescriptionmodal).modal("hide");

            this.prescriptionhistory_key = (new Date()).getTime();
        },
        deletePrescription(prescription) {
            this.$confirm({
                title: "Remove Treatment Prescription",
                message: `Are you sure you want to remove treatment prescription <strong>${prescription.main_treatment_code} ${prescription.main_treatment_description}</strong>?`,
                button: {
                    yes: "Yes",
                    no: "Cancel"
                },
                auth: true,
                callback: (confirmed, password) => {
                    if(confirmed && password) {
                        this.$http.delete(`prescriptions/${prescription.id}`, {
                            headers: {
                                "x-1991-request-password": btoa(password)
                            }
                        }).then((response) => {
                            this.$toasted.success(`Treatment prescription ${prescription.main_treatment_code} ${prescription.main_treatment_description} deleted successfully.`, {
                                duration: 3000
                            });

                            this.listPrescriptions();
                            this.closePrescription();
                        }).catch((error) => {
                            console.error(error);

                            let message = `Failed to delete treatment prescription ${prescription.main_treatment_code} ${prescription.main_treatment_description}.`;
                            if(401 === error.response?.status) {
                                message = "Unable to delete treatment prescription. Incorrect password.";
                            } else if(403 === error.response?.status) {
                                message = "Unable to delete treatment prescription. Incorrect access right.";
                            }

                            this.$toasted.error(message, {
                                duration: 3000
                            });
                        });
                    }
                }
            });
        },
        editAfterSales(prescription) {
            let aftersales = prescription.aftersales;

            if(aftersales && aftersales.created) {
                this.aftersalesformdata = clonedeep(aftersales);

                $(this.$refs.aftersalesmodal).modal("show");
            } else {
                this.aftersalesformdata = {
                    id: prescription.id
                };

                $(this.$refs.aftersalesmodal).modal("show");
            }
        },
        afterSalesSavingListener(saving) {
            this.saving_aftersales = saving;
        },
        afterSalesSaved(aftersales) {
            let prescription = this.prescription;

            if(!prescription || prescription.id !== aftersales.id) {
                prescription = this.prescriptions.find((_prescription) => {
                    return _prescription.id === aftersales.id;
                });
            }

            if(prescription && prescription.id === aftersales.id) {
                this.$set(prescription, "aftersales", aftersales);
            }

            $(this.$refs.aftersalesmodal).modal("hide");

            this.aftersaleshistory_key = (new Date()).getTime();

            this.listOutstandingPrescriptions();
        },
        deleteAfterSales(prescription) {
            if(prescription.aftersales.id) {
                this.$confirm({
                    title: "Remove After Sales Record",
                    message: `Are you sure you want to remove the after sales record for treatment <strong>${prescription.main_treatment_code} ${prescription.main_treatment_description}</strong>?`,
                    button: {
                        yes: "Yes",
                        no: "Cancel"
                    },
                    auth: true,
                    callback: (confirmed, password) => {
                        if(confirmed && password) {
                            this.$http.delete(`aftersales/${prescription.aftersales.id}`, {
                                headers: {
                                    "x-1991-request-password": btoa(password)
                                }
                            }).then((response) => {
                                this.$toasted.success(`After sales record for treatment ${prescription.main_treatment_code} ${prescription.main_treatment_description} deleted successfully.`, {
                                    duration: 3000
                                });

                                this.$delete(prescription, "aftersales");
                            }).catch((error) => {
                                console.error(error);

                                let message = `Failed to delete after sales record for treatment ${prescription.main_treatment_code} ${prescription.main_treatment_description}.`;
                                if(401 === error.response?.status) {
                                    message = "Unable to delete after sales record. Incorrect password.";
                                } else if(403 === error.response?.status) {
                                    message = "Unable to delete after sales record. Incorrect access right.";
                                }

                                this.$toasted.error(message, {
                                    duration: 3000
                                });
                            });
                        }
                    }
                });
            }
        },
        showCustomerHistory() {
            if(this.isAdmin) {
                $(this.$refs.customerhistorymodal).modal("show");
            }
        },
        showConsultationHistory() {
            if(this.isAdmin) {
                $(this.$refs.consultationhistorymodal).modal("show");
            }
        },
        showPrescriptionHistory() {
            if(this.isAdmin) {
                $(this.$refs.prescriptionhistorymodal).modal("show");
            }
        },
        showAfterSalesHistory() {
            if(this.isAdmin) {
                $(this.$refs.aftersaleshistorymodal).modal("show");
            }
        }
    },
    watch: {
        "photo.edit"(value) {
            if(value) {
                $(this.$refs.photocroppermodal).modal("show");

                this.$nextTick(() => {
                    if(!this.$refs.imageeditor) {
                        return;
                    }

                    let cropper = new Cropper(this.$refs.imageeditor, {
                        aspectRatio: 1 / 1,
                        viewMode: 1,
                    });
                    this.photo.cropper = cropper;
                });
            } else {
                $(this.$refs.photocroppermodal).modal("hide");

                if(this.photo.cropper) {
                    this.photo.cropper.destroy();
                    this.photo.cropper = false;
                }
            }
        },
        customer() {
            if(this.customer.photo_ext) {
                let photo = new URL(`${process.env.VUE_APP_MEDIA_BASE_URL}/customer/photo/${this.id}.min${this.customer.photo_ext}`);
                photo.searchParams.set("timestamp", (new Date()).getTime());

                const image = new Image();
                image.onload = () => {
                    this.photo.src = image.src;
                };

                image.onerror = () => {
                    if(!image.hasError) {
                        photo = new URL(`${process.env.VUE_APP_MEDIA_BASE_URL}/customer/photo/${this.id}${this.customer.photo_ext}`);
                        photo.searchParams.set("timestamp", (new Date()).getTime());

                        image.hasError = true;
                        image.src = photo.toString();
                    } else {
                        image.src = this.photo_placeholder;
                    }
                };
                image.src = photo.toString();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.back.link {
    display: inline-block;
    font-size: 1.35rem;
    margin-bottom: 1rem;
}

.photo-wrapper {
    position: relative;
    display: inline-block;
    margin: auto;

    .ui.photo.image {
        aspect-ratio: 1;
        max-width: 300px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }

    .ui.upload.button {
        position: absolute;
        bottom: 0;
        right: 0;

        ::v-deep {
            label {
                cursor: pointer;
            }
        }
    }
}

.ui.customer.grid {
    > .column {
        &:first-child {
            max-width: 360px;
        }

        &:last-child {
            flex: 1;
            min-width: 0;
        }

        @media only screen and (max-width: 1024px) {
            &:first-child {
                max-width: none;
            }
        }
    }
}

.ui.tabular.menu {
    border-bottom-color: rgba(0, 0, 0, .05);

    .active.item {
        border-color: rgba(0, 0, 0, .05);
        border-bottom: none;
    }

    &[class*="top attached"] ~ .segment[class*="bottom attached"] {
        border-color: rgba(0, 0, 0, .04);
        border-top: none;
    }
}

.ui.edit.button {
    position: sticky;
    z-index: 1;
    top: calc(56px + 1em);
}

.prescription-header {
    position: sticky;
    top: 56px;
    background: #fff;
    z-index: 1;
}

.prescription-footer {
    position: sticky;
    bottom: 1em;
    z-index: 1;
}

.ui.prescription.modal {
    ::v-deep {
        .ProseMirror {
            max-height: calc(100vh - 500px);
            overflow: auto;
        }
    }
}

.ui.action.menu {
    .ui.dropdown {
        .menu {
            > .active.item,
            > .selected.item {
                font-weight: 400 !important;
                color: rgba(0,0,0,.87)!important;

                &:not(:hover) {
                    background: none !important;
                }
            }
        }
    }
}

.ui.outstanding.label {
    position: relative;
    left: 0.5em;
    top: -0.35em;
    font-size: .55rem;
}

.treatment-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li {
        position: relative;
        margin: 0;
        padding-left: 2em;

        &:last-child {
            &::before {
                content: none;
                bottom: auto;
                height: 1.5em;
            }
        }

        &:first-child {
            &::before {
                content: "";
                top: -1.5em;
            }
        }

        &::before {
            border-right: 2px dotted rgba(211, 211, 211, 0.75);
            width: 2px;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            transform: translate(0, 0.5em);
            left: calc(0.5em + 2px);
            z-index: 0;
        }

        &::after {
            position: absolute;
            top: 6px;
            left: 0.5em;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #a333c8;
            z-index: 9;
        }
    }
}

.additional-treatments-table {
    tr {
        vertical-align: baseline;

        td:first-child {
            padding-right: 1em;
        }
    }
}

#aftersales-wrapper {
    @media only screen and (min-width: 768px) {
        display: flex;
        grid-gap: 0.5em;

        ::v-deep {
            > .comment {
                width: 38.2%;
            }

            > .collection {
                flex: 1;
                min-width: 0;
            }
        }
    }

    @media only screen and (min-width: 1025px) {
        ::v-deep {
            .collection {
                .ui.list {
                    column-count: 2;
                    column-gap: 2em;

                    > .item {
                        break-inside: avoid;
                    }
                }
            }
        }
    }
}

.ui.oustanding.message {
    margin-bottom: 1.5rem;
}

.ui.prescriptions.table {
    @media only screen and (max-width: 1280px) {
        thead th {
            padding-top: .46428571em !important;
            position: relative;

            .ui.button {
                position: absolute;
                right: 0;
                top: 0;
                white-space: nowrap;
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        thead th {
            vertical-align: top;
        }
    }
}



@media only screen and (min-width: 768px) {
    .ui.new-prescription.mobile.button {
        display: none;
    }

    .ui.mobile.oustanding.message {
        display: none;
    }

    .ui.contact.grid {
        > .row {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .ui.new-prescription.mobile.button {
        margin-bottom: 1em;
    }

    .ui.desktop.oustanding.message {
        display: none;
    }

    .ui.prescription.modal {
        ::v-deep {
            .ProseMirror {
                max-height: calc(100vh - 550px);
            }
        }
    }

    .ui.table.responsive.unstackable {
        tbody {
            tr {
                position: relative;

                td:first-child {
                    padding-right: 2em; //to give way for the action menu
                }

                .ui.action.menu {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 564px) {
    .ui.prescription.modal {
        ::v-deep {
            .ProseMirror {
                max-height: calc(100vh - 600px);
            }
        }
    }
}
</style>